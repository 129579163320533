<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>				
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<el-table size="mini" :max-height="height" :data="tableData" border="" stripe v-loading="loading"
				:show-overflow-tooltip="true">
				<el-table-column type="index" label="序号" width="80px"  align="center"></el-table-column>			
				<!-- <el-table-column prop="water_no" label="订单号" align="center"></el-table-column> -->
				<el-table-column prop="card" label="卡号" width="200px" align="center"></el-table-column>
				<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
				<el-table-column prop="" label="金额" align="center">
					<template slot-scope="scope">
						{{scope.row.money |  tofixed}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div class="textCenter">
							{{scope.row.state_name}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="note" label="备注" align="center"></el-table-column>
				<el-table-column prop="add_time" label="报损时间" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.add_time">
							{{scope.row.add_time.split(" ")[0]}}
							<br>
							{{scope.row.add_time.split(" ")[1]}}
						</div>
						
					</template>
				</el-table-column>
				
			</el-table>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				height: 0,
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				date: null,
				
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
			this.lossList();
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
		
			//搜索按钮
			getData() {
				this.page = 1;
				this.lossList();
			},

			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.lossList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.lossList()
			},
			lossList() {
				if(!this.date){
					this.$message({
						type: 'error',
						message:"请选择日期"
					})
					return
				}
				let data = {
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					page: this.page,
					size: this.pageSize,
				}
				this.disLoading = true
				this.$api.lossList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped" lang="scss">
	.formBoxbd {
		width: 80%;
		margin: 0 auto;
	}
	
</style>
<style type="text/css">
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 178px;
	    height: 178px;
	    line-height: 178px;
	    text-align: center;
	  }
	  .avatar {
	    width: 178px;
	    height: 178px;
	    display: block;
	  }
</style>
